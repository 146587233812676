import React, { Component } from "react";
import Header from "../common/header";
import Footer from "../common/footer";
import MainPane from "../common/mainPane";
import "../../css/homePage.css";

class HomePage extends Component {
  render() {
    return (
      <div className="homepage-container">
        <Header />
        <MainPane />
        <Footer />
      </div>
    );
  }
}

export default HomePage;
