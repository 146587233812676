import React, { Component } from "react";
import "../../css/queryResultPane.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRightLong,
  faCaretDown,
  faCaretRight,
  faCircleCheck,
  faMagnifyingGlass,
  faTriangleExclamation,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";
import {
  publish,
  subscribe,
  QueryResultsLoadedEvent,
  EraserPressedEvent,
  LogQueryServerResponseEvent,
  LogQueryServerErrorEvent,
} from "../../functions/events";

class QueryResultPane extends Component {
  logKeyMap = {
    receive_time: "Receive Time",
    source: "Source",
    destination: "Destination",
    destination_port: "Destination Port",
    firewall_rule: "Firewall Rule",
    zone_from: "Zone From",
    zone_to: "Zone To",
    action: "Action",
    session_end_reason: "Session End Reason",
    device_name: "Device Name",
  };

  state = {
    logElementMap: {},
    logSummary: {
      error: null,
      result: null,
      firewallRules: null,
      sourceZone: null,
      destinationZone: null,
      deviceName: null,
      sessionEndReason: null,
      sourceHost: null,
      destinationHost: null,
      destinationPort: null,
      timeWindow: null,
      total: 0,
    },
  };

  componentDidMount() {
    subscribe(LogQueryServerResponseEvent, this.logDataCallback);
    subscribe(EraserPressedEvent, this.onErase);
    subscribe(LogQueryServerErrorEvent, this.onServerResponseError);
  }

  logDataCallback = (event) => {
    this.processSummaryData(
      event.responseData.allowedTraffic,
      event.responseData.deniedTraffic,
      event.responseData.sourceHost,
      event.responseData.destinationHost,
      event.responseData.destinationPort,
      event.responseData.timeWindow,
      event.responseData.logsList.length
    );
    this.generateLogElements(event.responseData.logsList);

    publish(QueryResultsLoadedEvent);
  };

  onErase = (event) => {
    this.setState({
      logElementMap: {},
      logSummary: {
        error: null,
        result: null,
        firewallRules: null,
        sourceZone: null,
        destinationZone: null,
        deviceName: null,
        sessionEndReason: null,
        sourceHost: null,
        destinationHost: null,
        destinationPort: null,
        timeWindow: null,
        total: 0,
      },
    });
  };

  onServerResponseError = (event) => {
    const logSummary = {
      error: event.error,
      result: null,
      firewallRules: null,
      sourceZone: null,
      destinationZone: null,
      deviceName: null,
      sessionEndReason: null,
      sourceHost: event.responseData.sourceHost,
      destinationHost: event.responseData.destinationHost,
      destinationPort: event.responseData.destinationPort,
      timeWindow: event.responseData.timeWindow,
      total: 0,
    };
    this.setState({ logSummary });
    this.setState({ logElementMap: {} });
    publish(QueryResultsLoadedEvent);
  };

  generateLogElements = (logList) => {
    var logElementMap = {};
    var logDataMap = {};
    for (let index in logList) {
      const logDict = logList[index];
      const uniqueKey = `${logDict.receive_time}-${index}`;
      logElementMap[uniqueKey] = this.createLogGraphic(
        uniqueKey,
        logDict,
        true
      );
      logDataMap[uniqueKey] = {
        data: logDict,
        isCollasped: true,
      };
    }
    this.setState({ logElementMap: logElementMap });
    this.setState({ logDataMap: logDataMap });
  };

  generateSupportEmailLink = (summaryData) => {
    const requestParams = {
      sourceHost: summaryData.sourceHost,
      destinationHost: summaryData.destinationHost,
      destinationPort: summaryData.destinationPort,
      timeWindow: summaryData.timeWindow,
    };
    const body =
      `Issue Details\n-------------\n\n` +
      `Date Time: ${Date().toLocaleString()}\n\nRequest Parameters: ${JSON.stringify(
        requestParams
      )}\n\nError Object: ${JSON.stringify(
        summaryData.error
      )}\n\n-------------\nWrite any comments below:\n`;

    const mailtoLink = `mailto:NCF-Automation@loblaw.ca?subject=${encodeURIComponent(
      "Network Traffic Checker Issue"
    )}&body=${encodeURIComponent(body)}`;

    return (
      <React.Fragment>
        <button>
          <a href={mailtoLink}>
            <FontAwesomeIcon icon={faEnvelope} />
            &nbsp; Send Feedback
          </a>
        </button>
        <p
          style={{
            color: "var(--query-field-note-box-font-color)",
            fontSize: "0.95em",
            paddingTop: "12px",
          }}
        >
          <span style={{ fontWeight: "bold" }}>Note:</span> this error could be
          the result of a temporary issue. If it is the first time seeing this
          message, please try running the query (with 60 seconds between each
          run) at least once more before sending error feedback to the support
          team.
        </p>
      </React.Fragment>
    );
  };

  renderResultSummary = () => {
    const data = this.state.logSummary;
    var message = "";
    var resultMsg = "";
    var feedbackButton = null;
    var errorType = "";
    var errorMessage = "";

    var parameter_description = (
      <React.Fragment>
        <span style={{ fontWeight: "bold" }}>Source Host:</span>&nbsp;'
        {data.sourceHost || "Any"}' &nbsp;
        <FontAwesomeIcon icon={faArrowRightLong} />
        &nbsp; <span style={{ fontWeight: "bold" }}>Destination Host:</span>
        &nbsp; '{data.destinationHost || "Any"}',&nbsp;
        <span style={{ fontWeight: "bold" }}>Destination Port:</span>&nbsp;'
        {data.destinationPort || "Any"}', over&nbsp;
        <span style={{ fontWeight: "bold" }}>Time Window:</span> '
        {data.timeWindow}'.
      </React.Fragment>
    );

    if (data.error) {
      console.log("Error: ", data.error);
      if (data.error.response.status === 400) {
        errorType = "Parameter Error: ";
        errorMessage =
          "the server does not accept the following request parameters:";
      } else {
        errorType = "Server Error: ";
        errorMessage =
          "the server responded with an error for the following request:";
      }
      message = (
        <React.Fragment>
          <FontAwesomeIcon icon={faTriangleExclamation} />{" "}
          <span style={{ fontWeight: "bold" }}>{errorType}</span>
          <em>
            {errorMessage}&nbsp;<br></br>
            {parameter_description}
          </em>
        </React.Fragment>
      );
      feedbackButton = this.generateSupportEmailLink(data);
    } else if (data.result && data.total === 0) {
      message = (
        <React.Fragment>
          <em>
            No log data received for&nbsp;
            {parameter_description}
            Try updating the time window query parameter to receive a result.
          </em>
        </React.Fragment>
      );
      resultMsg = (
        <React.Fragment>
          Result: <mark>{data.result}</mark>
        </React.Fragment>
      );
    } else if (!data.result) {
      message = (
        <React.Fragment>
          <em>
            Fill in all the query fields until they all have a checkmark &nbsp;
            <FontAwesomeIcon icon={faCircleCheck} />
            , then click on the Search &nbsp;
            <FontAwesomeIcon icon={faMagnifyingGlass} /> &nbsp; button to
            execute a query. After a few seconds the results will load here and
            in the Logs Captured section.
          </em>
        </React.Fragment>
      );
    } else {
      message = (
        <React.Fragment>
          <em>
            Results listed below for&nbsp;
            {parameter_description}
          </em>
        </React.Fragment>
      );
      resultMsg = (
        <React.Fragment>
          Result: <mark>{data.result}</mark>
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        <div>{message}</div>
        {feedbackButton ? (
          <div className="qrp-summary-body-button-div">{feedbackButton}</div>
        ) : null}
        <h6 style={{ marginTop: "4px" }}>{resultMsg}</h6>
        {data.result && data.total > 0 && (
          <React.Fragment>
            <p>
              <span style={{ fontWeight: "bold" }}>Firewall Rules:</span>{" "}
              {data.firewallRules.join(", ")}
            </p>
            <p>
              <span style={{ fontWeight: "bold" }}>Source Zone:</span>{" "}
              {data.sourceZone.join(", ")}
            </p>
            <p>
              <span style={{ fontWeight: "bold" }}>Destination Zone:</span>{" "}
              {data.destinationZone.join(", ")}
            </p>
            <p>
              <span style={{ fontWeight: "bold" }}>Device Name:</span>{" "}
              {data.deviceName.join(", ")}
            </p>
            <p>
              <span style={{ fontWeight: "bold" }}>Session End Reason:</span>{" "}
              {data.sessionEndReason.join(", ")}
            </p>
          </React.Fragment>
        )}
        {data.result === "Indeterminate" && data.total === 0 && (
          <React.Fragment>
            <p
              style={{
                color: "var(--query-field-note-box-font-color)",
                fontSize: "0.95em",
              }}
            >
              <span style={{ fontWeight: "bold" }}>Note: </span>an{" "}
              <em>Indeterminate</em> result could indicate that the time window
              you've set is too small for the network traffic you're trying to
              query, or, in a more rare case, that a recent firewall change has
              affected this query.
            </p>

            <p
              style={{
                color: "var(--query-field-note-box-font-color)",
                fontSize: "0.95em",
              }}
            >
              If you believe the results are not correct, you can contact the
              team supporting this tool by clicking on the "Network Automation
              Team" link at the bottom left of this webpage.
            </p>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  };

  processSummaryData = (
    allowedTrafficDict,
    deniedTrafficDict,
    sourceHost,
    destinationHost,
    destinationPort,
    timeWindow,
    total
  ) => {
    var summaryDict = this.state.logSummary;
    summaryDict.firewallRules = allowedTrafficDict.firewall_rules.concat(
      deniedTrafficDict.firewall_rules
    );
    summaryDict.deviceName = allowedTrafficDict.device_names.concat(
      deniedTrafficDict.device_names
    );
    summaryDict.sourceZone = allowedTrafficDict.zones_from.concat(
      deniedTrafficDict.zones_from
    );
    summaryDict.destinationZone = allowedTrafficDict.zones_to.concat(
      deniedTrafficDict.zones_to
    );
    summaryDict.sessionEndReason =
      allowedTrafficDict.session_end_reasons.concat(
        deniedTrafficDict.session_end_reasons
      );
    summaryDict.total = total;

    summaryDict.error = null;
    summaryDict.sourceHost = sourceHost;
    summaryDict.destinationHost = destinationHost;
    summaryDict.destinationPort = destinationPort;
    summaryDict.timeWindow = timeWindow;
    if (allowedTrafficDict.total && deniedTrafficDict.total) {
      summaryDict.result = "Indeterminate";
    } else if (allowedTrafficDict.total) {
      summaryDict.result = "Allowed";
    } else if (deniedTrafficDict.total) {
      summaryDict.result = "Not Allowed";
    } else {
      summaryDict.result = "Indeterminate";
    }
    this.setState({ logSummary: summaryDict });
  };

  toggleLogGraphic = (event) => {
    var key = event.currentTarget.getAttribute("id");
    var logElementMap = this.state.logElementMap;
    var logDataMap = this.state.logDataMap;
    logElementMap[key] = this.createLogGraphic(
      key,
      this.state.logDataMap[key].data,
      !logDataMap[key].isCollasped
    );
    logDataMap[key].isCollasped = !logDataMap[key].isCollasped;
    this.setState({ logElementMap, logDataMap });
  };

  createLogGraphic = (uniqueKey, dataDict, isPreview) => {
    var row_divs = [];
    // Below code is responsible for populating the list of row divs for the log graphic
    if (!isPreview) {
      Object.entries(dataDict).forEach((row_data) => {
        const [field, value] = row_data;
        const field_label = this.logKeyMap[field];
        const row = (
          <div key={`${field}_row`} className="qrp-log-graphic-body-row">
            <p>
              <span>{`${field_label}:  ${value}`}</span>{" "}
            </p>
          </div>
        );
        row_divs.push(row);
      });
    } else {
      const row = (
        <div key={`received_time_row`} className="qrp-log-graphic-body-row">
          <p>
            <span>Receive Time: {dataDict.receive_time}</span>
          </p>
        </div>
      );
      row_divs.push(row);
    }

    // Below code is responsible for constructing the entire individual log graphic
    let logGraphic = (
      <div key={uniqueKey} className="qrp-log-graphic">
        <div className="qrp-log-graphic-column">
          <FontAwesomeIcon
            id={uniqueKey}
            onClick={this.toggleLogGraphic}
            icon={isPreview ? faCaretRight : faCaretDown}
          ></FontAwesomeIcon>

          {
            !isPreview && (
              <div></div>
            ) /* this is the div for the column line from the caret symbol */
          }
        </div>
        <div className="qrp-log-graphic-body">{row_divs}</div>
      </div>
    );
    return logGraphic;
  };

  render() {
    const logElements = Object.values(this.state.logElementMap);

    return (
      <div
        hidden={this.props.hidden}
        className="root-query-result-pane-container"
      >
        <div className="qrp-summary-container">
          <div className="qrp-summary-header">
            <h6>Summary</h6>
          </div>
          <div className="qrp-summary-body">{this.renderResultSummary()}</div>
        </div>
        <div className="qrp-log-list-container">
          <div className="qrp-log-list-header">Logs Captured</div>
          <div className="qrp-log-list-body">{logElements}</div>
          <div className="qrp-log-list-footer">Total: {logElements.length}</div>
        </div>
      </div>
    );
  }
}

export default QueryResultPane;
