import React, { Component } from "react";
import "../../css/queryField.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { content } from "../content/queryFieldTootip";
import { subscribe } from "../../functions/events";
import {
  faCheckCircle,
  faXmarkCircle,
  faCircleInfo,
} from "@fortawesome/free-solid-svg-icons";
import {
  faCircleCheck as farCircleCheck,
  faCircleXmark as farCircleXmark,
} from "@fortawesome/free-regular-svg-icons";
import { EraserPressedEvent } from "../../functions/events";

class QueryField extends Component {
  constructor(props) {
    super(props);
    this.state = {
      icon: faCircleInfo,
      altIcon: faCircleInfo,
      statusHover: false,
      color: "var(--query-field-info-icon-color)",
      altColor: "var(--query-field-info-icon-alt-color)",
      fieldValue: "",
    };
  }

  componentDidMount() {
    subscribe(EraserPressedEvent, this.handleErase);
  }

  handleChange = (event) => {
    this.setState({ fieldValue: event.target.value });
    let result = this.props.onChange(event);
    if (!event.target.value) {
      this.setState({
        icon: faCircleInfo,
        altIcon: faCircleInfo,
        color: "var(--query-field-info-icon-color)",
        altColor: "var(--query-field-info-icon-alt-color)",
      });
    } else if (result) {
      this.setState({
        icon: farCircleCheck,
        altIcon: faCheckCircle,
        color: "var(--query-field-validated-icon-color)",
        altColor: "var(--query-field-validated-icon-color)",
      });
    } else {
      this.setState({
        icon: farCircleXmark,
        altIcon: faXmarkCircle,
        color: "var(--query-field-invalidated-icon-color)",
        altColor: "var(--query-field-invalidated-icon-color)",
      });
    }
  };

  alternateStatusStyle = () => {
    const { color, altColor, altIcon, icon } = this.state;
    this.setState({
      color: altColor,
      altColor: color,
      icon: altIcon,
      altIcon: icon,
    });
  };

  handleErase = () => {
    this.setState({
      fieldValue: "",
      icon: faCircleInfo,
      altIcon: faCircleInfo,
      color: "var(--query-field-info-icon-color)",
      altColor: "var(--query-field-info-icon-alt-color)",
    });
  };

  renderTooltip = () => {
    var tooltipMsg = null;
    if (this.state.icon === faCircleInfo) {
      tooltipMsg = content[this.props.name].description;
    } else if (this.state.icon === farCircleCheck) {
      tooltipMsg = content[this.props.name].valid;
    } else {
      tooltipMsg = content[this.props.name].invalid;
    }
    return <Tooltip id={`${this.props.name}-tooltip`}>{tooltipMsg}</Tooltip>;
  };

  render() {
    const { inputLabel } = this.props;

    return (
      <div className="query-field-container">
        <div className="label-half-pill">
          <p>{inputLabel}</p>
        </div>
        <input
          name={this.props.name}
          type="text"
          onChange={this.handleChange}
          value={this.state.fieldValue}
        />
        <OverlayTrigger
          key={"top"}
          placement={"top"}
          overlay={this.renderTooltip()}
        >
          <div
            className="status-half-pill"
            onMouseEnter={() => this.setState({ statusHover: true })}
            onMouseLeave={() => this.setState({ statusHover: false })}
          >
            <FontAwesomeIcon
              style={{
                color: this.state.statusHover
                  ? this.state.altColor
                  : this.state.color,
              }}
              icon={
                this.state.statusHover ? this.state.altIcon : this.state.icon
              }
            />
          </div>
        </OverlayTrigger>
      </div>
    );
  }
}

export default QueryField;
