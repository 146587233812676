import React, { Component } from "react";
import "../../css/queryPane.css";
import QueryField from "./queryField";
import QueryDropDown from "./queryDropDown";
import { subscribe, QueryResultsLoadedEvent } from "../../functions/events";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import RingLoader from "react-spinners/RingLoader";
import { faNoteSticky } from "@fortawesome/free-solid-svg-icons";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

class QueryFieldPanel extends Component {
  state = {
    loadingOverlayEnabled: false,
    buttonHover: false,
  };

  componentDidMount() {
    subscribe(QueryResultsLoadedEvent, this.endLoadingOverlay);
  }

  searchButtonClicked = (event) => {
    this.setState({ loadingOverlayEnabled: true }, (event) =>
      this.props.onSearchClick(event)
    );
  };

  endLoadingOverlay = (event) => {
    this.setState({ loadingOverlayEnabled: false });
  };

  renderTooltip = () => {
    if (!this.props.searchButtonDisabled) {
      return <React.Fragment></React.Fragment>;
    }
    var tooltipMsg =
      "You must set at least one of 'Source' or 'Destination' parameters";

    return <Tooltip id="tooltip">{tooltipMsg}</Tooltip>;
  };

  render() {
    return (
      <div className="query-pane-bottom-center-root">
        {this.state.loadingOverlayEnabled && (
          <div className="query-pane-bottom-center-overlay">
            <RingLoader
              color={"blue"}
              loading={true}
              size={"5em"}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
            <span
              style={{
                color: "var(--query-pane-search-overlay-font-color)",
              }}
            >
              Loading...
            </span>
          </div>
        )}
        <div className="query-pane-bottom-container">
          <div className="query-pane-bottom-center">
            <QueryField
              name="sourceHost"
              inputLabel="Source"
              onChange={this.props.onChange}
            />
            <QueryField
              name="destinationHost"
              inputLabel="Destination"
              onChange={this.props.onChange}
            />
            <QueryField
              name="destinationPort"
              inputLabel="Destination Port"
              onChange={this.props.onChange}
            />
            <QueryDropDown
              name="timeWindow"
              inputLabel="Time Window"
              onChange={this.props.onChange}
            />
          </div>
          <OverlayTrigger
            key={"bottom"}
            placement={"bottom"}
            overlay={this.renderTooltip()}
          >
            <div className="query-pane-bottom-button">
              <button
                disabled={this.props.searchButtonDisabled}
                onClick={this.searchButtonClicked}
                style={{
                  backgroundColor:
                    this.state.buttonHover && !this.props.searchButtonDisabled
                      ? "rgb(75, 75, 75)"
                      : "rgb(48, 48, 48)",
                  cursor: this.props.searchButtonDisabled
                    ? "not-allowed"
                    : "auto",
                }}
                onMouseEnter={() => {
                  this.setState({ buttonHover: true });
                }}
                onMouseLeave={() => {
                  this.setState({ buttonHover: false });
                }}
              >
                <FontAwesomeIcon icon={faMagnifyingGlass} /> <span>Search</span>
              </button>
            </div>
          </OverlayTrigger>
          <div className="query-pane-bottom-text">
            <div id="query-pane-bottom-text-header">
              <p>
                <FontAwesomeIcon
                  style={{
                    fontSize: "small",
                    color: "var(--query-field-note-box-icon-color)",
                  }}
                  icon={faNoteSticky}
                ></FontAwesomeIcon>{" "}
                Note
              </p>
            </div>
            <div id="query-pane-bottom-text-body">
              <p>
                The Network Traffic Checker checks the Azure (Cloud) Panorama
                firewall log archive to determine whether traffic is{" "}
                <em>ALLOWED</em> or <em>NOT ALLOWED</em>. Therefore, you must
                generate traffic from your source to destination on the desired
                port before using this tool. If not, this will just search for
                historical traffic.
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default QueryFieldPanel;
