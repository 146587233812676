import React, { Component } from "react";
import "../../css/queryPane.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMagnifyingGlass,
  faChevronDown,
  faChevronUp,
} from "@fortawesome/free-solid-svg-icons";
import ControlPanel from "./controlPanel";
import QueryResultPane from "./queryResultPane";
import QueryFieldPanel from "./queryFieldPanel";
import QueryHelpPane from "./queryHelpPane";
import {
  validateHostname,
  validatePortNumber,
} from "../../functions/validators";
import {
  publish,
  subscribe,
  QueryResultsLoadedEvent,
  EraserPressedEvent,
} from "../../functions/events";
import getSummaryAndLogs from "../../functions/api";

class QueryPane extends Component {
  state = {
    wasResultPaneLastClicked: true,
    queryHelpPaneCollasped: true,
    queryResultPaneCollasped: true,
    queryParameters: { timeWindow: "Last 60 Seconds" },
    queryParametersValidated: false,
  };

  componentDidMount() {
    subscribe(QueryResultsLoadedEvent, this.onLoadedResult);
  }

  toggleBottomPane = () => {
    const {
      wasResultPaneLastClicked,
      queryHelpPaneCollasped,
      queryResultPaneCollasped,
    } = this.state;
    if (wasResultPaneLastClicked) {
      this.setState({
        queryResultPaneCollasped: !queryResultPaneCollasped,
        queryHelpPaneCollasped: true,
      });
    } else {
      this.setState({
        queryResultPaneCollasped: true,
        queryHelpPaneCollasped: !queryHelpPaneCollasped,
      });
    }
  };

  onResultsButtonClick = () => {
    this.setState({
      wasResultPaneLastClicked: true,
      queryHelpPaneCollasped: true,
      queryResultPaneCollasped: false,
    });
  };

  searchButtonOnClick = () => {
    /* Do not initiate query request when the query parameters are not validated*/
    if (!this.state.queryParametersValidated) {
      return;
    }
    getSummaryAndLogs(
      this.state.queryParameters.sourceHost,
      this.state.queryParameters.destinationHost,
      this.state.queryParameters.destinationPort,
      this.state.queryParameters.timeWindow
    );
  };

  onLoadedResult = () => {
    this.setState({
      queryResultPaneCollasped: false,
      queryHelpPaneCollasped: true,
      wasResultPaneLastClicked: true,
    });
  };

  infoButtonOnClick = () => {
    this.setState({
      wasResultPaneLastClicked: false,
      queryHelpPaneCollasped: false,
      queryResultPaneCollasped: true,
    });
  };

  eraserButtonOnClick = () => {
    publish(EraserPressedEvent);
    this.setState({
      queryParameters: { timeWindow: "Last 60 Seconds" },
      queryParametersValidated: false,
    });
  };

  onFieldQueryPaneChange = (event) => {
    /* Update query parameter state to reflect the change */
    let queryParameters = this.state.queryParameters;
    queryParameters[event.target.name] = event.target.value;
    this.setState({ queryParameters: queryParameters });

    /* Check if all query parameters are valid */
    const sourceHostValid = validateHostname(queryParameters.sourceHost);
    const destinationHostValid = validateHostname(
      queryParameters.destinationHost
    );
    const destinationPortValid = validatePortNumber(
      queryParameters.destinationPort
    );

    /* Update state to reflect current query parameter validation state */
    if (
      (sourceHostValid || !queryParameters.sourceHost) &&
      (destinationHostValid || !queryParameters.destinationHost) &&
      (destinationPortValid || !queryParameters.destinationPort) &&
      (queryParameters.sourceHost || queryParameters.destinationHost)
    ) {
      this.setState({ queryParametersValidated: true });
    } else {
      this.setState({ queryParametersValidated: false });
    }

    /* Return if the updated parameter which fired the event is valid now */
    if (event.target.name === "sourceHost") {
      return sourceHostValid;
    } else if (event.target.name === "destinationHost") {
      return destinationHostValid;
    } else if (event.target.name === "destinationPort") {
      return destinationPortValid;
    }
  };

  render() {
    return (
      <div className="root-query-pane-container">
        <div className="query-pane-header-container">
          <div className="query-pane-header-container-center">
            <h6>Query Network Traffic Logs</h6>
            <FontAwesomeIcon
              style={{ color: "gray", paddingLeft: "2mm" }}
              icon={faMagnifyingGlass}
            />
          </div>
        </div>
        {
          <QueryFieldPanel
            onSearchClick={this.searchButtonOnClick}
            onChange={this.onFieldQueryPaneChange}
            searchButtonDisabled={!this.state.queryParametersValidated}
          />
        }
        {
          <ControlPanel
            resultsButtonOnClick={this.onResultsButtonClick}
            infoButtonOnClick={this.infoButtonOnClick}
            eraserButtonOnClick={this.eraserButtonOnClick}
            docsButtonOnClick={null}
          />
        }
        {this.state.collaspedBottom ? null : this.state.bottomPane}
        {<QueryResultPane hidden={this.state.queryResultPaneCollasped} />}
        {<QueryHelpPane hidden={this.state.queryHelpPaneCollasped} />}
        <div
          className="query-pane-footer-container"
          onClick={this.toggleBottomPane}
        >
          <FontAwesomeIcon
            icon={
              this.state.queryHelpPaneCollasped &&
              this.state.queryResultPaneCollasped
                ? faChevronDown
                : faChevronUp
            }
          />
        </div>
      </div>
    );
  }
}

export default QueryPane;
