import React from "react";

export var content = {
  sourceHost: {
    description: (
      <React.Fragment>
        The Source Host (IP Address or Domain Name) of the network traffic you
        want to query
      </React.Fragment>
    ),
    valid: <React.Fragment>Validated Source Host</React.Fragment>,
    invalid: (
      <React.Fragment>
        The Source Host needs to be of the form: 'x.x.x.x' where x is an integer
        between 0 and 255 (inclusive), or a valid domain name
      </React.Fragment>
    ),
  },
  destinationHost: {
    description: (
      <React.Fragment>
        The Destination Host (IP Address or Domain Name) of the network traffic
        you want to query
      </React.Fragment>
    ),
    valid: <React.Fragment>Validated Destination Host</React.Fragment>,
    invalid: (
      <React.Fragment>
        The Destination Host needs to be of the form: 'x.x.x.x' where x is an
        integer between 0 and 255 (inclusive), or a valid domain name
      </React.Fragment>
    ),
  },
  destinationPort: {
    description: (
      <React.Fragment>
        The Destination Port of the network traffic you want to query
      </React.Fragment>
    ),
    valid: <React.Fragment>Validated Destination Port</React.Fragment>,
    invalid: (
      <React.Fragment>
        The Destination Port needs to be an integer between 0 and 65535
        (inclusive)
      </React.Fragment>
    ),
  },
  timeWindow: {
    description: (
      <React.Fragment>
        The Time Window sets the limit for the oldest firewall log to query
      </React.Fragment>
    ),
  },
};
