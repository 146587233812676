import React, { Component } from "react";
import "../../css/mainPane.css";
import QueryPane from "./queryPane";

class MainPane extends Component {
  render() {
    return <div className="root-main-container">{<QueryPane />}</div>;
  }
}

export default MainPane;
