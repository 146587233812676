import {
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
  Route,
} from "react-router-dom";
import React from "react";
import HomePage from "./components/routes/homePage";
import ErrorPage from "./components/routes/errorPage";

//  react-router-dom documentation: https://reactrouter.com/en/main/start/tutorial
const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<HomePage />} errorElement={<ErrorPage />}></Route>
  )
);

function App() {
  return (
    <React.Fragment>
      <RouterProvider router={router} />
    </React.Fragment>
  );
}

export default App;
