function subscribe(eventName, listener) {
  document.addEventListener(eventName, listener);
}

function unsubscribe(eventName, listener) {
  document.removeEventListener(eventName, listener);
}

function publish(eventName) {
  const event = new Event(eventName);
  document.dispatchEvent(event);
}

const QueryResultsLoadedEvent = "queryResultsLoaded";
const EraserPressedEvent = "eraserPressed";
const LogQueryServerResponseEvent = "logQueryServerResponse";
const LogQueryServerErrorEvent = "logQueryServerError";

export {
  publish,
  subscribe,
  unsubscribe,
  QueryResultsLoadedEvent,
  EraserPressedEvent,
  LogQueryServerResponseEvent,
  LogQueryServerErrorEvent,
};
