export function validateHostname(hostname) {
  /* If the argument is null do not attempt to validate */
  if (!hostname) {
    return false;
  }

  /* Hostname regex */
  const hostRE =
    /^((?!-)[A-Za-z0-9_-]{1,63}(?<!-))(\.(?!-)[A-Za-z0-9_-]{1,63}(?<!-))*(?<!\.)$/;
  const ipRE =
    /^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)[.]){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;

  const matchedHost = hostname.match(new RegExp(hostRE, "g"));
  const matchedIP = hostname.match(new RegExp(ipRE, "g"));
  if (matchedHost || matchedIP) {
    return true;
  } else {
    return false;
  }
}

export function validatePortNumber(portNumber) {
  if (!portNumber) {
    return false;
  }
  try {
    const re = /^\d+$/;
    const integer = parseInt(portNumber);
    if (
      portNumber.match(new RegExp(re, "g")) &&
      0 <= integer &&
      integer <= 65535
    ) {
      return true;
    }
    return false;
  } catch (err) {
    return true;
  }
}
