import { useRouteError } from "react-router-dom";
import "../../css/errorPage.css";
import "../../css/homePage.css";
import Header from "../common/header";
import Footer from "../common/footer";
import { faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function ErrorPage() {
  const error = useRouteError();
  console.error(error);

  return (
    <div className="homepage-container">
      <Header />
      <div className="error-page-root-container">
        <div className="error-page-container">
          <div className="error-page-header">
            <h1>Error</h1>
          </div>
          <div className="error-page-body">
            <p>An unexpected error has occurred.</p>
            <p>
              <FontAwesomeIcon
                style={{ color: "gray" }}
                className="expanding-button"
                icon={faTriangleExclamation}
              />
              <i style={{ padding: "2mm" }}>
                {error.statusText || error.message}
              </i>
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
