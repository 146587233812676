import React, { Component } from "react";
import "../../css/queryHelpPane.css";
import { faFileCircleQuestion } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class QueryHelpPane extends Component {
  render() {
    return (
      <div
        hidden={this.props.hidden}
        className="root-query-help-pane-container"
      >
        <div className="query-help-pane-header">
          <h6>How the Query Works</h6>
        </div>
        <div className="query-help-pane-body">
          <ul>
            <li>
              The Network Traffic Checker is a web application which helps the
              Loblaw Network Team and any other Loblaw colleagues check whether
              traffic is being allowed or not by our private firewalls (keep
              reading for details).
            </li>
            <li>
              The Network Traffic Checker checks the Azure (Cloud) Panorama Palo
              Alto Firewall real-time log database to see if traffic that was
              queried by the user is being allowed or not by a particular
              firewall rule.
            </li>
            <li>
              Therefore, the Network Traffic Checker tool does
              <span style={{ fontWeight: "bold" }}> not</span> include the
              Panorama on-prem firewalls hosted in our data centres.
            </li>
            <li>
              <span style={{ fontWeight: "bold" }}>Note:</span> this tool cannot
              check more than 1000 traffic logs, regardless of the time window
              that is set in your query. Therefore, the tool only returns the
              results of parsing the latest 1000 traffic logs for your query
              (within the specified time window). This is a limitation of the
              Panorama API that services this web application's back-end server.
            </li>
          </ul>
        </div>
        <div className="query-help-pane-header">
          <h6>Query Network Traffic Logs: Parameters</h6>
        </div>
        <div className="query-help-pane-body">
          <table className="table table-hover table-dark">
            <thead>
              <tr>
                <th scope="col">Parameter</th>
                <th scope="col">Explanation</th>
                <th scope="col">Example</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Source</td>
                <td>
                  This parameter represents the Source Host (IP Address or
                  Domain Name) of the traffic you are trying to query. The IP
                  Address should be formatted as four consecutive integers from
                  0 to 256, each split by a period. If you enter a domain name,
                  it must follow domain name conventions.
                </td>
                <td>192.162.1.1</td>
              </tr>
              <tr>
                <td>Destination</td>
                <td>
                  This parameter represents the Destination Host of the traffic
                  you are trying to query. It should follow the same format as
                  the Source argument mentioned above.
                </td>
                <td>Same as above</td>
              </tr>
              <tr>
                <td>Destination Port</td>
                <td>
                  The Destination Port represents the Destination Port of the
                  traffic you are trying to query. The value should be an
                  integer between 0 and 65535.
                </td>
                <td>22 (SSH traffic)</td>
              </tr>
              <tr>
                <td>Time Window</td>
                <td>
                  The Time Window parameter determines how far back in time you
                  want to check. For example, setting this parameter to "Last 60
                  seconds" would check traffic that has only been sent/received
                  in the past minute.
                </td>
                <td>"Last 60 Seconds"</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="query-help-pane-header">
          <h6>Query Network Traffic Logs: Output</h6>
        </div>
        <div className="query-help-pane-body">
          <table className="table table-hover table-dark">
            <thead>
              <tr>
                <th scope="col">Parameter</th>
                <th scope="col">Explanation</th>
                <th scope="col">Example</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Result</td>
                <td>
                  This represents whether the queried traffic was either allowed
                  or not allowed by the Panorama Palo Alto Firewalls. In the
                  case where some traffic was allowed and some traffic was
                  denied then the result will be indeterminate. In addition, if
                  no traffic was observed for the query parameters specified
                  then indeterminate will also be displayed.
                </td>
                <td>Allowed</td>
              </tr>
              <tr>
                <td>Firewall Rules</td>
                <td>
                  This is a list of all the Firewall Rules that were applied on
                  the traffic over the time window queried.
                </td>
                <td>"Load Balancer to SAP cloud prod"</td>
              </tr>
              <tr>
                <td>Source Zone</td>
                <td>
                  The zone where the traffic originated from; SD-WAN policy
                  rules use predefined zones for internal path selection and
                  traffic forwarding purposes.
                </td>
                <td>"EQUINIX_DATACENTER"</td>
              </tr>
              <tr>
                <td>Destination Zone</td>
                <td>
                  The zone where the traffic's path terminated; SD-WAN policy
                  rules use predefined zones for internal path selection and
                  traffic forwarding purposes.
                </td>
                <td>"IBM_TR2_MS_PRE"</td>
              </tr>
              <tr>
                <td>Session End Reason</td>
                <td>
                  This is a list of all the reasons why the traffic's session
                  ended.
                </td>
                <td>"tcp-fin"</td>
              </tr>
              <tr>
                <td>Device Name</td>
                <td>
                  This is a list of the names of the firewall devices that
                  captured the traffic over the specified time window.
                </td>
                <td>"LCLCITEQ1-PFW02"</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="query-help-pane-header">
          <h6>Support</h6>
        </div>
        <div className="query-help-pane-body">
          <ul>
            <li>
              Contact the Network Automation Team at{" "}
              <em>NCF-Automation@loblaw.ca</em> if you experience any problems
              while using the web application or need any additional assistance
              with using the application.
            </li>
            <li>
              If you would like to learn more about how the Network Traffic
              Checker works (specifically the back-end) or are interested in
              integrating this service with another application then check out
              the Confluence page by clicking{"  "}
              <FontAwesomeIcon
                className="expanding-button"
                icon={faFileCircleQuestion}
              />
              {"  "}
              in the toolbar.
            </li>
          </ul>
        </div>
      </div>
    );
  }
}

export default QueryHelpPane;
