import React, { Component } from "react";
import "../../css/queryField.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { content } from "../content/queryFieldTootip";
import { subscribe } from "../../functions/events";

class QueryDropDown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: "Last 60 Seconds",
    };
  }

  componentDidMount() {
    subscribe("eraserPressed", this.handleErase);
  }

  renderTooltip = () => {
    return (
      <Tooltip id={`${this.props.name}-tooltip`}>
        {content[this.props.name].description}
      </Tooltip>
    );
  };

  handleErase = () => {
    this.setState({ value: "Last 60 Seconds" });
  };

  handleChange = (event) => {
    this.setState({ value: event.target.value });
    this.props.onChange(event);
  };

  render() {
    const { inputLabel } = this.props;

    return (
      <div className="query-field-container">
        <div className="label-half-pill">
          <p>{inputLabel}</p>
        </div>
        <select
          name={this.props.name}
          onChange={this.handleChange}
          value={this.state.value}
        >
          <option value="Last 60 Seconds">Last 60 Seconds</option>
          <option value="Last 15 Minutes">Last 15 Minutes</option>
          <option value="Last Hour">Last Hour</option>
          <option value="Last 6 Hours">Last 6 Hours</option>
          <option value="Last 12 Hours">Last 12 Hours</option>
          <option value="Last 24 Hours">Last 24 Hours</option>
          <option value="Last Calendar Day">Last Calendar Day</option>
          <option value="Last 7 Days">Last 7 Days</option>
          <option value="Last 7 Calendar Days">Last 7 Calendar Days</option>
          <option value="Last Calendar Week">Last Calendar Week</option>
          <option value="Last 30 Days">Last 30 Days</option>
          <option value="Last Calendar Month">Last Calendar Month</option>
        </select>

        <OverlayTrigger
          key={"top"}
          placement={"top"}
          overlay={this.renderTooltip()}
        >
          <div className="status-half-pill">
            <FontAwesomeIcon icon={faCircleInfo} />
          </div>
        </OverlayTrigger>
      </div>
    );
  }
}

export default QueryDropDown;
