import React, { Component } from "react";
import "../../css/header.css";
import LCL_LOGO from "../../images/LCL_LOGO.png";
import ROUTER_SYMBOL from "../../images/router_image.png";

class Header extends Component {
  render() {
    return (
      <div className="header-container">
        <div className="header-item-edge">
          <img
            className="corp-header-logo"
            src={LCL_LOGO}
            alt="Corporate Logo"
          ></img>
        </div>
        <div className="header-item-centered">
          <div className="header-item-rounded-background">
            <h4 className="header-item-centered-h4">Network Traffic Checker</h4>
            <img
              className="header-title-symbol"
              src={ROUTER_SYMBOL}
              alt="router symbol"
            ></img>
          </div>
        </div>
        <div className="header-item-edge"></div>
      </div>
    );
  }
}

export default Header;
