import React, { Component } from "react";
import "../../css/footer.css";

class Footer extends Component {
  render() {
    return (
      <div className="footer-container">
        <div className="footer-item-edge">
          <a href={"mailto:NCF-Automation@loblaw.ca"}>
            Network Automation Team
          </a>
        </div>
        <div className="footer-item-edge">
          <a
            href={
              "https://gitlab.lblw.ca/ltncf/ltncf-netauto/ltncf-netauto-upr/NetworkTrafficChecker"
            }
          >
            v1.2.0
          </a>
        </div>
      </div>
    );
  }
}

export default Footer;
