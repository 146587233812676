import axios from "axios";
import {
  LogQueryServerResponseEvent,
  LogQueryServerErrorEvent,
} from "./events";

const API_HOSTNAME = process.env.REACT_APP_SERVER_HOSTNAME;
const RESOURCE_URL = "/panorama/logs_detailed";
var BASE_URL = `https://${API_HOSTNAME}/`;

// Use http outside of the GCP environment
if (process.env.REACT_APP_LOCAL_DEV_ENV === "1") {
  BASE_URL = `http://${API_HOSTNAME}/`;
  console.log(
    `BASE_URL: ${BASE_URL} and ${process.env.REACT_APP_LOCAL_DEV_ENV}`
  );
}

export default function getSummaryAndLogs(
  sourceHost,
  destinationHost,
  destinationPort,
  timeWindow
) {
  const queryParams = {
    source_host: sourceHost,
    destination_host: destinationHost,
    destination_port: destinationPort,
    time_window: timeWindow,
  };

  axios
    .request({
      baseURL: BASE_URL,
      url: RESOURCE_URL,
      params: queryParams,
      timeout: 10 * 1000, // Milliseconds till we timeout
    })
    .then((response) => {
      var event = new Event(LogQueryServerResponseEvent);
      event.responseData = {
        allowedTraffic: response.data.allowed_traffic,
        deniedTraffic: response.data.denied_traffic,
        sourceHost: sourceHost,
        destinationHost: destinationHost,
        destinationPort: destinationPort,
        timeWindow: timeWindow,
        logsList: response.data.result,
      };
      document.dispatchEvent(event);
    })
    .catch((error) => {
      var event = new Event(LogQueryServerErrorEvent);
      event.error = error;
      event.responseData = {
        sourceHost: sourceHost,
        destinationHost: destinationHost,
        destinationPort: destinationPort,
        timeWindow: timeWindow,
      };
      document.dispatchEvent(event);
    });
}
