import React, { Component } from "react";
import "../../css/controlPanel.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleInfo,
  faEraser,
  faFileCircleQuestion,
  faSquarePollVertical,
} from "@fortawesome/free-solid-svg-icons";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { content } from "../content/controlPanelTooltip";

class ControlPanel extends Component {
  render() {
    return (
      <div className="root-control-panel-container">
        <div className="control-panel-center-container">
          <OverlayTrigger
            key="search-button-tooltip"
            placement="top"
            overlay={
              <Tooltip id={`search-button-tooltip`}>
                {content.searchButton}
              </Tooltip>
            }
          >
            <button onClick={this.props.resultsButtonOnClick}>
              <FontAwesomeIcon
                className="expanding-button"
                icon={faSquarePollVertical}
              />
            </button>
          </OverlayTrigger>
          <OverlayTrigger
            key="information-button-overlay"
            placement="top"
            overlay={
              <Tooltip id={`information-button-tooltip`}>
                {content.informationButton}
              </Tooltip>
            }
          >
            <button onClick={this.props.infoButtonOnClick}>
              <FontAwesomeIcon
                className="expanding-button"
                icon={faCircleInfo}
              />
            </button>
          </OverlayTrigger>
          <OverlayTrigger
            key="eraser-button-overlay"
            placement="top"
            overlay={
              <Tooltip id={`eraser-button-tooltip`}>
                {content.eraserButton}
              </Tooltip>
            }
          >
            <button onClick={this.props.eraserButtonOnClick}>
              <FontAwesomeIcon className="expanding-button" icon={faEraser} />
            </button>
          </OverlayTrigger>
          <OverlayTrigger
            key="docs-button-overlay"
            placement="top"
            overlay={
              <Tooltip id={`docs-button-tooltip`}>{content.docsButton}</Tooltip>
            }
          >
            <a
              href="https://confluence.lblw.cloud/display/LTND/Network+Traffic+Checker"
              rel="noreferrer"
            >
              <button onClick={this.props.docsButtonOnClick}>
                <FontAwesomeIcon
                  className="expanding-button"
                  icon={faFileCircleQuestion}
                />
              </button>
            </a>
          </OverlayTrigger>
        </div>
      </div>
    );
  }
}

export default ControlPanel;
